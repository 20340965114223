import api from './api';

class UserService {
  getMainContent() {
    return api.get('/front/all');
  }

  getUploadBoard() {
    return api.get('/front/upload/all');
  }

  uploadFile(file) {
    return api.post('/front/upload',
        file,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
  }
    sendInviteSMS() {
        return api.get('/front/sms/send')
    }
    resendInviteSMS() {
        return api.get('/front/sms/resend')
    }
    getExcel() {
        return api.get('/front/excel', {responseType: 'blob'})
    }
    getMissedSMSStatuses() {
        return api.get('/front/sms/getmissed')
    }
}

export default new UserService();
