import axios from "axios";

const instance = axios.create({
  baseURL: "https://i.exeed.ru/api/v1",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":  "*",
  },
});

export default instance;
