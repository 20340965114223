<template>
  <div class="container">
    <loading v-model:active="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"/>
    <header class="jumbotron">
      <div class="card">
      <h5 class="card-header">Внутренняя статистика Exeed Inviter</h5>
      <div class="card-body">
          <ul v-if="content" class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-primary">
              Всего уникальных номеров в базе:
              <span class="badge badge-primary badge-pill">{{content.allcount}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-success">
              Количество номеров которым <strong>уже отправлено</strong>
              <span class="badge badge-primary badge-pill">{{content.isSended}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-success">
              Количество номеров которым <strong>отправлено 2 РАЗ</strong>
              <span class="badge badge-primary badge-pill">{{content.isReSended}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-success">
              Количество номеров которые <strong>точно перешли по ссылке</strong>
              <span class="badge badge-primary badge-pill">{{content.checkedreferStatusChanged}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-success">
              Количество номеров которые <strong>перешли по ссылке после повторного сообщения</strong>
              <span class="badge badge-primary badge-pill">{{content.checkedreferStatusAfterResendChanged}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-warning">
              Количество номеров которые <strong>возможно(!) увидели сообщение</strong>
              <span class="badge badge-primary badge-pill">{{content.referStatusChanged}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-warning">
              Количество номеров которым  <strong>еще НЕ отправлено</strong>
              <span class="badge badge-primary badge-pill">{{content.notSended}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-warning">
              Количество номеров которым  <strong>Не может быть доставлено(номер не верен \ запрет смс \ т.д)</strong>
              <span class="badge badge-primary badge-pill">{{content.smsCantDelivery}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-warning">
              Из них по причине  <strong>телефон в роуминге\отключен\sim не используется</strong>
              <span class="badge badge-primary badge-pill">{{content.smsCantDeliveryCausePhone}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center list-group-item-info">
              (стата для админа!)Количество номеров у которых получен ответ от sms-провайдера:
              <span class="badge badge-primary badge-pill">{{content.smsStatusChanged}}</span>
            </li>
          </ul>
        <h5 v-else class="card-title">Нечего показать</h5>
      </div>
    </div>
    </header>
    <div class="card">
      <h5 class="card-header">Выгрузка статистики Exeed Inviter</h5>
      <div class="card-body">
        <button v-on:click="getExcelStat" type="button" class="btn btn-danger btn-lg btn-block">Скачать</button>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import {saveAs} from 'file-saver';



export default {
  name: "HomePage",
  data() {
    return {
      content: "",
      isLoading: false,
      fullPage: true,
    };
  },
  methods: {
    getExcelStat() {
        this.isLoading = true;
        UserService.getExcel()
            .then(response => {
              saveAs(response.data, 'stats.xlsx')
              return response.data
            })
            .then(data => {
              /*We are Tooooo Fast with golang so sleep a bit */
              this.error = null
              setTimeout(() => {
                this.isLoading = false
              }, 5000)
              console.log(data)
            })
            .catch(error => {
              this.isLoading = false
              this.error = error.response.data.error
              console.log(error.response.data.error)
            });
    },
    refreshData() {
        return setTimeout(() => {
          UserService.getMainContent().then(
              (response) => {
                this.content = response.data;
              },
              (error) => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
              }
          );
          this.refreshData()
        }, 10000)
    }
  },
  mounted() {
    UserService.getMainContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    this.refreshData();
  },
};
</script>
