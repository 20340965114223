import { createWebHistory, createRouter } from "vue-router";
import HomePage from "./components/Home.vue";
import LoginPage from "./components/Login.vue";

const BoardUpload = () => import("./components/BoardUpload")
const BoardSending = () => import("./components/BoardSending")

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/upload",
    name: "upload",
    // lazy-loaded
    component: BoardUpload,
  },
  {
    path: "/sending",
    name: "sending",
    // lazy-loaded
    component: BoardSending,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

 router.beforeEach((to, from, next) => {
   const publicPages = ['/login'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem('user');

   // trying to access a restricted page + not logged in
   // redirect to login page
   if (authRequired && !loggedIn) {
     next('/login');
   } else {
     next();
   }
 });

export default router;